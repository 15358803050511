import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'pro-callout',
    templateUrl: './pro-callout.component.html',
    standalone: true,
    imports: [CommonModule]
})

export class ProCalloutComponent {
    @Input() displayStyle: string = "boxed-grey"; // unboxed, boxed-grey, boxed-blue

    public get displayStyleBoxedBlue(): boolean {
        return this.displayStyle === "boxed-blue";
    }
}
