import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { UninvasiveErrorComponent } from './uninvasive-error.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'error-header',
    templateUrl: './error-header.component.html',
    standalone: true,
    animations: [
        trigger('animationWrapper', [
            transition(':enter', [
                query('@slideInTop', [
                    style({ opacity: 0, 'top': '-20px' }),
                    stagger(-350, [
                        animate(300, style({ opacity: 1, 'top': '0' }))
                    ])
                ], { optional: true })
            ])
        ]),
        trigger('dismissHeader', [
            transition('false => true', [
                style({ opacity: .5, 'margin-top' : '0' }),
                animate(200, style({ opacity: 0, 'margin-top': '-10px' }))
            ])
        ]),
        trigger('slideInTop', [])
    ],
    imports: [CommonModule]
})

export class ErrorHeaderComponent extends UninvasiveErrorComponent {
    public dismissHeaderRequested = false;

    public requestDismissHeader(): void {
        this.dismissHeaderRequested = true;
    }

    public dismissHeader(event: any): void {
        if (event.toState === 'true') {
            this.selfReference.destroy();
        }
    }
}
