import { ErrorBarComponent } from '@Error-Handler/Components/error-bar.component';
import { ErrorHeaderComponent } from '@Error-Handler/Components/error-header.component';
import { ErrorModalComponent } from '@Error-Handler/Components/error-modal.component';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ErrorDTO, UnresolvedError } from '@shared/Models/ErrorHandlerModel';
import { FSResult } from '@shared/Models/FSResultModel';
import { SharedErrorHandlerService } from '@shared/Services/shared-error-handler.service';
import { BehaviorSubject, Observable } from 'rxjs';
declare var fisheriesModule: any;

@Injectable({providedIn: 'root'})
export class ErrorHandlerService {
    private _origin: string;

    constructor(
        private _http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string,
        private _sharedErrorHandlerService: SharedErrorHandlerService
    ) {
        this._origin = originUrl;
    }

    public postUnresolvedErrors(): Observable<FSResult<UnresolvedError[]>> {
        return this._http.post<FSResult<UnresolvedError[]>>(`${this._origin}/errors/currentshopper?cid=${(window as any)
            .fs.clientId}`, JSON.stringify(fisheriesModule.unresolvedErrors));
    }

    public registerErrorContainers(): void {
        if (typeof window !== 'undefined') {
            (<any>window).fisheriesModule = (<any>window).fisheriesModule ?? {};
            const fsm: any = (<any>window).fisheriesModule;

            if (!fsm.unresolvedErrors) {
                fsm.unresolvedErrors = [];
                fsm.createErrorBarSubject = new BehaviorSubject<ErrorDTO>(null);
                fsm.createErrorModalSubject = new BehaviorSubject<ErrorDTO>(null);
                fsm.createErrorHeaderSubject = new BehaviorSubject<ErrorDTO>(null);
                fsm.errorRetrySubject = new BehaviorSubject<boolean>(false);
                fsm.updateErrorsSubject = new BehaviorSubject<boolean>(false);
            }
        }
    }

    public createErrorModal(dto: ErrorDTO): void {
        var componentRef = dto.vcRef.createComponent(ErrorModalComponent);

        componentRef.instance.title = dto.title || 'Oops! Something went wrong.';
        componentRef.instance.message = dto.message || 'Request failed. Please try again.';
        componentRef.instance.allowDismissal = dto.allowDismissal;
        componentRef.instance.displayRetry = dto.displayRetry;
        componentRef.instance.displayGoBack = dto.displayGoBack;
        componentRef.instance.goBackUrl = dto.goBackUrl;
        componentRef.instance.goBackText = dto.goBackText;
        componentRef.instance.dismissOnTimeout = dto.dismissOnTimeout;
        componentRef.instance.hideReload = dto.hideReload;
        componentRef.instance.hideHelp = dto.hideHelp;
        componentRef.instance.urgency = dto.urgency;
        componentRef.instance.selfReference = componentRef;
        componentRef.changeDetectorRef.detectChanges();

        this.pushToUnresolvedErrors(dto);
    }

    public createErrorBar(dto: ErrorDTO): void {
        let componentRef = dto.vcRef.createComponent(ErrorBarComponent);

        componentRef.instance.title = dto.title || 'Oops! Something went wrong.';
        componentRef.instance.message = dto.message || 'Request failed. Please try again.';
        componentRef.instance.urgency = dto.urgency;
        componentRef.instance.displayGoBack = dto.displayGoBack;
        componentRef.instance.goBackUrl = dto.goBackUrl;
        componentRef.instance.goBackText = dto.goBackText;
        componentRef.instance.dismissOnTimeout = dto.dismissOnTimeout;
        componentRef.instance.selfReference = componentRef;
        componentRef.changeDetectorRef.detectChanges();

        this.pushToUnresolvedErrors(dto);
    }

    public createErrorHeader(dto: ErrorDTO): void {
        this._sharedErrorHandlerService.errorHeaderCreated = true;

        let componentRef = dto.vcRef.createComponent(ErrorHeaderComponent);

        componentRef.instance.title = dto.title || 'Oops! Something went wrong.';
        componentRef.instance.message = dto.message || 'Request failed. Please try again.';
        componentRef.instance.selfReference = componentRef;
        componentRef.changeDetectorRef.detectChanges();

        this.pushToUnresolvedErrors(dto);
    }

    private pushToUnresolvedErrors(dto: ErrorDTO): void {
        const error = new UnresolvedError();
        error.componentRef = dto.vcRef;
        error.title = dto.title;
        error.message = dto.message;
        error.httpErrorResponse = dto.httpErrorResponse;
        error.fsError = dto.fsError;

        fisheriesModule.unresolvedErrors.push(error);

        fisheriesModule.updateErrorsSubject.next(true);
    }
}
