import { Injectable } from '@angular/core';
import { ErrorDTO } from '@shared/Models/ErrorHandlerModel';
declare var fisheriesModule: any;

@Injectable({providedIn: 'root'})
export class SharedErrorHandlerService {
    public errorHeaderCreated: boolean;

    public sendCreateErrorModal(dto: ErrorDTO): void {
        fisheriesModule.createErrorModalSubject.next(dto);
    }

    public sendCreateErrorBar(dto: ErrorDTO): void {
        fisheriesModule.createErrorBarSubject.next(dto);
    }

    public sendCreateErrorHeader(dto: ErrorDTO): void {
        fisheriesModule.createErrorHeaderSubject.next(dto);
    }
}
