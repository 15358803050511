import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { UninvasiveErrorComponent } from './uninvasive-error.component';
import { FieldErrorDisplayComponent } from '@shared/Components/field-error-display.component';
import { GetHelpComponent } from './get-help.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'error-bar',
    templateUrl: './error-bar.component.html',
    standalone: true,
    imports: [
        CommonModule,
        FieldErrorDisplayComponent,
        GetHelpComponent
    ],
    animations: [
        trigger('animationWrapper', [
            transition(':enter', [
                query('@slideInTop', [
                    style({ opacity: 0, 'top': '-20px' }),
                    stagger(-350, [
                        animate(300, style({ opacity: 1, 'top': '0' }))
                    ])
                ], { optional: true })
            ])
        ]),
        trigger('dismissBar', [
            transition('false => true', [
                style({ opacity: .5, 'margin-top' : '0' }),
                animate(200, style({ opacity: 0, 'margin-top': '-10px' }))
            ])
        ]),
        trigger('slideInTop', [])
    ]
})

export class ErrorBarComponent extends UninvasiveErrorComponent {
    public dismissBarRequested = false;

    public requestDismissBar(): void {
        this.dismissBarRequested = true;
    }

    public dismissBar(event: any): void {
        if (event.toState === 'true') {
            this.selfReference.destroy();
        }
    }
}
