import { CommonModule, CurrencyPipe } from '@angular/common';
import { Component, Injector } from '@angular/core';
import { ResultCardComponent } from '@shared/Components/Result/result-card.component';
import { ResultFeaturedComponent } from '@shared/Components/Result/result-featured.component';
import { AssortmentPrice } from '@shared/Models/OrderHelper';

@Component({
    selector: 'assortment-result-price',
    templateUrl: './assortment-result-price.component.html',
    imports: [
        CommonModule,
        CurrencyPipe
    ],
    standalone: true
})

export class AssortmentResultPriceComponent {
    public price: AssortmentPrice;

    constructor(public injector: Injector) {}

    ngOnInit() {
        let parentComponent : any = this.injector.get(ResultCardComponent, null) || this.injector.get(ResultFeaturedComponent, null);

        if(parentComponent){
            this.price = <AssortmentPrice>parentComponent.price;
        }
    }

    public get fullPrice(): number {
        return this.price?.fullPrice;
    }

    public get salePrice(): number {
        return this.price?.salePrice;
    }

    public get isOnSale(): boolean {
        return this.price?.isOnSale;
    }

    public get minPrice(): number {
        return this.price?.minPrice;
    }

    public get maxPrice(): number {
        return this.price?.maxPrice;
    }
    
    public get shouldShowPriceRange(): boolean {
        if (this.minPrice > 0 && this.maxPrice > 0)
            return this.minPrice < this.maxPrice;

        return false;
    }
}
