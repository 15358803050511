import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ComponentRef, Input, OnDestroy, ViewRef } from '@angular/core';
declare var fisheriesModule: any;

@Component({
    selector: 'base-component',
    template: 'this is a base component',
    standalone: true,
    imports: [CommonModule]
})
export class ErrorComponent implements OnDestroy {
    public retryRequestInProgress: boolean;
    public reloadRequested: boolean;
    public goBackRequested: boolean;
    public retryRequestLimitReached: boolean;
    public retryRequestUnsuccessful: boolean;

    @Input() title: string;
    @Input() message: string;
    @Input() displayGoBack: boolean;
    @Input() goBackUrl: string;
    @Input() goBackText: string;
    @Input() dismissOnTimeout: number;
    @Input() selfReference: ComponentRef<{}>;

    constructor(
        public changeDetector: ChangeDetectorRef
    ) { }

    ngOnInit() {
        if (this.dismissOnTimeout) {
            setTimeout(() => this.selfReference.destroy(), this.dismissOnTimeout);
        }
    }

    ngOnDestroy() {
        const removeIndex = fisheriesModule.unresolvedErrors
            .findIndex((x) => x.componentRef === this.selfReference);

        fisheriesModule.unresolvedErrors.splice(removeIndex, 1);

        fisheriesModule.updateErrorsSubject.next(true);
    }

    public retryRequest(): void {
        this.retryRequestInProgress = true;
        this.retryRequestUnsuccessful = null;
        fisheriesModule.errorRetrySubject.next(true);

        fisheriesModule.errorRetryObservable.subscribe((res) => {
            setTimeout(() => {
                this.retryRequestInProgress = false;
                this.retryRequestUnsuccessful = true;

                if (res === false) {
                    this.retryRequestLimitReached = true;
                }

                const vr: ViewRef = (this.changeDetector as ViewRef);
                if (vr && !vr.destroyed) {
                    this.changeDetector.detectChanges();
                }

            }, 1000); // timeout temporary
        });
    }

    public reloadPage(): void {
        this.reloadRequested = true;

        window.location.reload();
    }

    public goBack(): void {
        this.goBackRequested = true;
        location.href = this.goBackUrl;
    }
}
