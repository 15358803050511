import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PageManagerSystemHealth } from '@shared/Models/PageManagerModel';
import { PageManagerService } from '@shared/Services/page-manager.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
    selector: 'system-health',
    templateUrl: './system-health.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatProgressSpinnerModule,
        TooltipModule
    ]
})

export class SystemHealthComponent {
    public pageManagerSystemHealth: PageManagerSystemHealth;

    constructor(private _pageManagerService: PageManagerService) {
        this._pageManagerService.getPageManagerSystemHealth().subscribe((res) => {
            this.pageManagerSystemHealth = res;
        })
    }

    public get statusClass(): string {
        var statusClass = "fs-status-dot--";

        if (this.pageManagerSystemHealth.catalogChangeHandlerHealthy)
            return statusClass += "green";
        if (this.pageManagerSystemHealth.pageManagerHealthy)
            return statusClass += "yellow";
        else
            return statusClass += "red";
    }

    public get statusTooltip(): string {
        if (this.pageManagerSystemHealth.catalogChangeHandlerHealthy)
            return "All services are healthy. Changes can be made and synced successfully.";
        if (this.pageManagerSystemHealth.pageManagerHealthy)
            return "Some services are not healthy. Changes can be made, but may take time to be synced.";
        else
            return "Services are not healthy. Changes may not go through successfully.";
    }
}
