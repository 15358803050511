import { Component, Input } from '@angular/core';
import { ErrorComponent } from './error.component';
import { ErrorUrgency } from '@shared/Models/ErrorHandlerModel';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'base-component',
    template: 'this is a base component',
    standalone: true,
    imports: [CommonModule]
})
export class UninvasiveErrorComponent extends ErrorComponent {
    @Input() urgency: string;

    public get isSuccess(): boolean {
        return this.urgency === ErrorUrgency.SUCCESS;
    }
}
