import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
    selector: "quick-links",
    standalone: true,
    template: `
        <div class="fs-box--outline">
            <h4 class="fs-h4 fs-uppercase">Quick Links</h4>
            <ul class="fs-list">
                <li><a href="/help">Contact Us / Get Help</a></li>
                <li><a href="/rma">Start a Return</a></li>
                <li><a href="/returns">Return Policy</a></li>
                <li><a href="/location">Store Hours & Location</a></li>
                <li><a href="/fisheries-supply-gift-card">Gift Cards</a></li>
                <li><a href="/pro">Pro & Wholesale Accounts</a></li>
                <li><a href="/swap-meet">Swap Meet</a></li>
            </ul>
        </div>    
    `,
    imports: [CommonModule]
})
export class QuickLinksComponent {}