import { MailingListService } from '@Mailing-List-Signup/Services/mailing-list.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorDTO, ErrorUrgency } from '@shared/Models/ErrorHandlerModel';
import { SubscriptionRequestDTO } from '@shared/Models/SubscriptionRequestDTO';
import { SharedErrorHandlerService } from '@shared/Services/shared-error-handler.service';

@Component({
    selector: 'app-mailing-list-signup',
    templateUrl: './app-mailing-list-signup.component.html'
})

export class AppMailingListSignupComponent implements OnInit {
    private _mailingListFormSubmitAttempt: boolean;

    public mailingListForm: FormGroup;
    public submitInProgress: boolean;

    constructor(
        private _builder: FormBuilder,
        private _mailingListService: MailingListService,
        private _errorHandler: SharedErrorHandlerService) { }

    ngOnInit(): void {
        this.mailingListForm = this._builder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    public isFieldInvalid(field: string): boolean {
        return (
            (!this.mailingListForm.get(field).valid && this.mailingListForm.get(field).touched) ||
            (!this.mailingListForm.get(field).valid && this._mailingListFormSubmitAttempt)
        );
    }

    public submit(): void {
        this._mailingListFormSubmitAttempt = true;

        if (this.mailingListForm.valid) {
            this.submitInProgress = true;

            var dto = new SubscriptionRequestDTO();
            dto.email = this.mailingListForm.value.email;

            this._mailingListService.subscribeEmailToMailingList(dto).subscribe((res) => {
                if (res.success) {
                    var dto = new ErrorDTO();
                    dto.title = "Sign Up Successful";
                    dto.message = "You will now receive emails for product specials, promotions, email only coupons, events & pro specific news.";
                    dto.allowDismissal = true;
                    dto.urgency = ErrorUrgency.SUCCESS;

                    this._errorHandler.sendCreateErrorModal(dto);

                    this._mailingListFormSubmitAttempt = false;
                    this.mailingListForm.reset();
                } else {
                    var dto = new ErrorDTO();
                    dto.title = "Sign Up Unsuccessful";
                    dto.message = "An error has occurred. Please try again.";

                    this._errorHandler.sendCreateErrorModal(dto);
                }

                this.submitInProgress = false;
            });
        }
    }

}
