import { AppErrorHandlerComponent } from '@Error-Handler/Components/app-error-handler.component';
import { provideHttpClient } from '@angular/common/http';
import { Injector, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

@NgModule({
  imports: [
    BrowserModule
  ],
  providers: [
    provideHttpClient(),
    provideAnimations(),
    { provide: 'ORIGIN_URL', useFactory: getOriginUrl }
  ]
})
export class ErrorHandlerModule {
  constructor(private injector: Injector) {}

  ngDoBootstrap() {
    const ErrorHandlerElement = createCustomElement(AppErrorHandlerComponent, { injector: this.injector });
    customElements.get('error-handler-app') || customElements.define('error-handler-app', ErrorHandlerElement);
  }
}

export function getOriginUrl(): string {
  if (typeof window !== 'undefined') {
    return window.location.origin;
  }
}
