import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ErrorHandlerService } from '@Error-Handler/Services/error-handler.service';

@Component({
    selector: 'get-help',
    templateUrl: './get-help.component.html',
    standalone: true,
    imports: [CommonModule]
})

export class GetHelpComponent  {
    constructor(private _errorHandlerService: ErrorHandlerService) {}

    public temporaryCreateTicketObject(): void {
        this._errorHandlerService.postUnresolvedErrors().subscribe((res) => {
            if (res.success) {
                // do something
            }
        });
    }
}
