<div class="container px-0 mb-5" *ngIf="assortments">
    <ng-container *ngFor="let assortment of assortments; let i = index">
        <assortment-slider *ngIf="displayAsSlider" 
            [assortment]="assortment" 
            [fsls]="fsls" 
            [fslsid]="assortment.assortmentId"></assortment-slider>

        <assortment-sidebar *ngIf="!displayAsSlider" 
            [assortment]="assortment" 
            [fsls]="fsls" 
            [fslsid]="assortment.assortmentId"></assortment-sidebar>
    </ng-container>
</div>