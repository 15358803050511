import { HMPWarningSchemeEnum } from './PersonaSettingsModel';
import { IResult, IResultPrice, ResultAvailability, ResultBrand } from './ResultModel';
import { ClickableImageContainer, ContentBlock, Link, OnPageData } from './sharedModels';

export class CatalogPage {
    public contentBlocks: ContentBlock[];
    public hmpActive: boolean;
    public hmpScheme: HMPWarningSchemeEnum;
    public shouldShowAssortments: boolean;
    public assortments: AssortmentSettings[];
    currentNavigation: string;
}

export class AssortmentSettings {
    public name: string;
    public filters: Map<string, string>;
}

export enum AssortmentStyle {
    SLIDER, SIDEBAR
}

export class Results {
    public results: IResult[];
    public resultId: string;
}

export class RefinementList {
    public title: string;
    public refinements: RefinementValue[];
    public maxVisibleRefinements: number;
    public maxVisibleRefinementsBuffer: number;
    public minShowAllRefinementsModal: number;
    public toggledOpen: boolean;
    public refinementDisplayType: RefinementDisplayTypeEnum;
    public ancestors: Link[];
    public hasActiveFilter: boolean;
    public clearLink: string;
    public isDisabled: boolean;
}

export enum RefinementDisplayTypeEnum {
    LINK = "LINK",
    CHECKBOX = "CHECKBOX",
    SWATCH = "SWATCH",
    TOGGLE = "TOGGLE",
    RANGE = "RANGE",
    REVIEW = "REVIEW",
    SWITCH = "SWITCH"
}

export class RefinementValue {
    public link: Link;
    public count: number;
    public tooltip: string;
    public isActive: boolean;
    public isDisabled: boolean;
    public imageUrl: string;
    public imageAltTag: string;
    public refinementBehavior: string;
}

export class RefinementRange extends RefinementList {
    public minValue: number;
    public maxValue: number;
    public floor: number;
    public ceil: number;
    public clearLink: string;
    public isRemovable: boolean;
}

export class RefinementSwitch extends RefinementList {
    public switchLink: string;
    public isSwitchedOn: boolean;
}

export class RefinementLink extends RefinementList {
}

export class SearchHeader {
    public activeQuery: string;
    public totalResults: number;
    public correctedQuery: string;
    public alternativeQueries: Link[];
}

export class ResultList {
    public results: IResult[];
}

export class CatalogResult implements IResult {
    public name: string;
    public productId: string;
    public shortCode: string;
    public itemId: string;
    public url: string;
    public count: number;
    public imageUrl: string;
    public imageAltTag: string;
    public reviewScore: number;
    public availability: ResultAvailability;
    public topLevelBrand: ResultBrand;
    public invMastUid: number;
    public price: CatalogPrice;
}



export class CatalogPrice implements IResultPrice {
    public isOnSale: boolean;
    public fullPrice: number;
    public minPrice: number;
    public maxPrice: number;
}

export class ActiveFilter {
    public title: string;
    public filter: Link;
    public isAncestor: boolean;
    public isRemovable: boolean;
}

export class ResultHeader {
    public resultCount: ResultCount;
    public breadcrumbs: Link[];
    public activeFilters: ActiveFilter[];
    public searchQuery: string;
    public sortDropdown: SortDropdown;
    public resultCountDropdown: ResultCountDropdown;
    public inStockDimension: RefinementList;
    public shouldShowDisplaySwitch: boolean;
    public clearActiveFiltersLink: string;
}

export class ResultCount {
    public totalResults: number;
    public startIndex: number;
    public endIndex: number;
}

export abstract class Dropdown {
    public title: string;
    public options: DropdownOption[]
}

export class DropdownOption {
    public link: Link;
    public isActive: boolean;
}

export class SortDropdown extends Dropdown { }

export class ResultCountDropdown extends Dropdown { }

export class ResultFooter {
    public resultCount: ResultCount;
    public pagination: Pagination;
    public sortDropdown: SortDropdown;
    public resultCountDropdown: ResultCountDropdown;
    public shouldShowDisplaySwitch: boolean;
}

export class Pagination {
    public totalResults: number;
    public itemsPerPage: number;
    public currentPage: number;
    public nextButton: Link;
    public previousButton: Link;
    public paginationLinks: Link[];
}

export class ResultWrapper {
    public contentBlocks: ContentBlock[];
}

export class ResultContainer {
    public resultContext: ResultContext;
    public resultSidebar: ResultSidebar;
    public resultHeader: ResultHeader;
    public resultList: ResultList;
    public discontinuedResults: ResultList;
    public resultFooter: ResultFooter;
    public navigation: ClickableImageContainer[];
    public onPageData: OnPageData;
}

export class ResultContext {
    public isMainDepartment: boolean;
    public isLeafDepartment: boolean;
    public hasBrandFilter: boolean;
    public hasOtherFilters: boolean;
    public filterKeyCount: number;
    public isSearchUrl: boolean;
}

export class ResultSidebar {
    public contentBlocks: ContentBlock[];
}

export class MobileFilterWrapper {
    public contentBlocks: ContentBlock[];
}

export class MobileFilterContainer {
    public sortDropdown: SortDropdown;
    public refinementLists: RefinementList[];
    public clearLink: string;
}
