import { Injectable } from "@angular/core";
import { SharedErrorHandlerService } from "@shared/Services/shared-error-handler.service";
import { LoggingService } from "@shared/Services/logging.service";
import { ErrorDTO } from "./ErrorHandlerModel";

@Injectable()
export class RetryHelper {
    constructor(
        private _errorHandler: SharedErrorHandlerService,
        private _loggingService: LoggingService) {}

    public exponenentialBackoff(action, initialDelay = 5000, exponent = 1.05, maxDelay = 180000, maxRetriesCallback?) {
        if (action()) {
            this._loggingService.logDeveloperInfo('Action returned true (SignalR is up). Retries stopped.');
        } else {
            if (exponent <= 1) {
                return;
            }

            var nextDelay = Math.pow(initialDelay, exponent);
    
            if (nextDelay < maxDelay) {
                setTimeout(() => {
                    this._loggingService.logDeveloperInfo('Requesting next retry.');
                    this.exponenentialBackoff(action, nextDelay, exponent, maxDelay, maxRetriesCallback);
                }, initialDelay);
            } else {
                this._loggingService.logDeveloperInfo('Max delay for retries reached.');
    
                if (maxRetriesCallback) {
                    this._loggingService.logDeveloperInfo('Callback specified. Calling component will manage response.');
                    maxRetriesCallback();
                }

                if (!this._errorHandler.errorHeaderCreated && !maxRetriesCallback) {
                    var dto = new ErrorDTO();
                    dto.title = "Hmmm.";
                    dto.message = "We're having trouble reconnecting...please refresh the page.";

                    this._errorHandler.sendCreateErrorHeader(dto);
                }
            }
        }
    }
}