<ng-container *ngIf="results">
    <h3 class="fs-uppercase mb-2 mt-5" *ngIf="name">{{name}}</h3>

    <div class="row">
        <ng-container *ngFor="let result of results; let i = index; trackBy: trackResults">
            <div class="col-12 col-md-6 col-lg-12" *ngIf="i < 2">
                <result-card
                    [result]="result"
                    [position]="i + 1"
                    (sendClick)="setClick($event, result, i + 1)"
                    [list]="list"
                    productImpression
                    [fsls]="fsls"
                    [fslsid]="fslsid">
                </result-card>
            </div> 
        </ng-container>
    </div>
</ng-container>
