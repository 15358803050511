import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ErrorHandlerService } from '@Error-Handler/Services/error-handler.service';
import { SettingsAccessor } from '@shared/Services/settings.accessor';
import { GetHelpComponent } from './get-help.component';

declare var fisheriesModule: any;

@Component({
    selector: 'app-error-handler',
    templateUrl: './app-error-handler.component.html',
    standalone: true,
    imports: [
        CommonModule,
        GetHelpComponent
    ]
})

export class AppErrorHandlerComponent implements OnInit {
    @ViewChild('errorModalContainer', { read: ViewContainerRef, static: true }) errorModalContainer: ViewContainerRef;
    @ViewChild('errorToastContainer', { read: ViewContainerRef, static: true }) errorToastContainer: ViewContainerRef;
    @ViewChild('errorBarContainer', { read: ViewContainerRef, static: true }) errorBarContainer: ViewContainerRef;
    @ViewChild('errorHeaderContainer', { read: ViewContainerRef, static: true }) errorHeaderContainer: ViewContainerRef;

    constructor(
        private _errorHandlerService: ErrorHandlerService,
        private _changeDetector: ChangeDetectorRef,
        private _settingsAccessor: SettingsAccessor) { }

    ngOnInit() {
        this._errorHandlerService.registerErrorContainers();

        fisheriesModule.updateErrorsSubject.asObservable().subscribe((res) => {
            if (res === true) {
                this._changeDetector.detectChanges();
            }
        });

        fisheriesModule.createErrorBarSubject.asObservable().subscribe((res) => {
            if (res != null) {
                res.vcRef = this.errorBarContainer;
                this._errorHandlerService.createErrorBar(res);
            }
        });

        fisheriesModule.createErrorModalSubject.asObservable().subscribe((res) => {
            if (res != null) {
                res.vcRef = this.errorModalContainer;
                this._errorHandlerService.createErrorModal(res);
            }
        });

        fisheriesModule.createErrorHeaderSubject.asObservable().subscribe((res) => {
            if (res != null) {
                res.vcRef = this.errorHeaderContainer;
                this._errorHandlerService.createErrorHeader(res);
            }
        });
    }

    public get errorBarPosition(): string {
        return this._settingsAccessor.errorBarPosition;
    }
}
