import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResultClickContext } from '@shared/Components/Result/ResultClickContext';
import { ResultClickHandler } from '@shared/Components/Result/ResultClickHandler';
import { ResultCardComponent } from '@shared/Components/Result/result-card.component';
import { ResultComponent } from '@shared/Components/Result/result.component';
import { ImpressionDirective, ProductImpressionDirective } from '@shared/Directives/sharedDirectives';
import { ProductList } from '@shared/Models/AnalyticsModel';
import { Assortment } from '@shared/Models/OrderHelper';
import { IResult } from '@shared/Models/ResultModel';

@Component({
    selector: 'assortment-sidebar',
    templateUrl: './assortment-sidebar.component.html',
    standalone: true,
    imports: [
        ResultCardComponent,
        ResultComponent,
        CommonModule,
        ProductImpressionDirective,
        ImpressionDirective
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResultClickHandler]
})

export class AssortmentSidebarComponent {
    @Input() assortment: Assortment;
    @Input() fsls: string;
    @Input() fslsid: string;

    constructor(private _resultClickHandler: ResultClickHandler) {}
    
    public get results() : IResult[]{
        return this.assortment?.results;
    }

    public get name() : string {
        return this.assortment?.name;
    }

    public get list(): string {
        return ProductList.RecommendationResultList;
    }

    public trackResults(index: number, result: IResult): string {
        return result.productId;
    }

    public setClick(clickContext: ResultClickContext, result: IResult, position: number): void {
        this._resultClickHandler.doResultClick(clickContext,this.list,position);
    }
}
