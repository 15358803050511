import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FSError } from '@shared/Models/FSResultModel';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SharedErrorHandlerService } from './shared-error-handler.service';
import { ErrorDTO } from '@shared/Models/ErrorHandlerModel';

@Injectable({ providedIn: 'root' })
export class FSErrorInterceptor implements HttpInterceptor {
    constructor(private _errorHandlerService: SharedErrorHandlerService) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((response) => {
                if (response instanceof HttpResponse) {
                    if (response.body) {
                        if (response.body.errors) {
                            if (response.body.errors.length > 0) {
                                for (let error of response.body.errors) {
                                    return this.respondToErrorCode(error, request, next);
                                }
                            }
                        }
                    }
                }
            })
        );
    }

    private respondToErrorCode(error: FSError, request: HttpRequest<any>, next: HttpHandler): void {
        switch (error.code) {
            case 102: {
                var dto = new ErrorDTO();
                dto.title = 'Unable to Place Order';
                dto.message = error.message;
                dto.fsError = error;
                dto.allowDismissal = true;

                this._errorHandlerService.sendCreateErrorModal(dto);

                break;
            }

            default:
                break;
        }
    }

}

