import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, WritableSignal, signal } from '@angular/core';
import { AssortmentSettings, AssortmentStyle } from '@shared/Models/CatalogModel';
import { Assortment, GetAssortmentRequest } from '@shared/Models/OrderHelper';
import { AssortmentService } from '@shared/Services/assortment-service';
import { AssortmentSliderComponent } from './assortment-slider.component';
import { CommonModule } from '@angular/common';
import { AnalyticsService } from '@shared/Services/analytics.service';
import { AssortmentSidebarComponent } from './assortment-sidebar.component';

@Component({
    selector: 'assortment-element',
    templateUrl: './assortment-element.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        AssortmentSliderComponent,
        AssortmentSidebarComponent
    ],
    providers: [
        AssortmentService,
        AnalyticsService
    ]
})

export class AssortmentElementComponent implements OnInit {
    private _assortments: WritableSignal<Assortment[]>;
    private _assortmentConfigs: AssortmentSettings[];

    @Input() set assortmentSettingsFactory(value: () => AssortmentSettings[]) {
        if (!this._assortmentConfigs) {
            this._assortmentConfigs = value();
            this.getAssortments();
        }
    }

    @Input() requestedAssortments: AssortmentSettings[];
    @Input() url: string;
    @Input() prependExplictAssortments = true;
    @Input() fsls: string;
    @Input() assortmentId: string;
    @Input() displayStyle = AssortmentStyle.SLIDER;

    @Output() onAssortmentLoaded = new EventEmitter<Assortment[]>();

    constructor(
        private _assortmentService: AssortmentService
    ) {
        this._assortments = signal<Assortment[]>([]);
    }

    ngOnInit() {
        if (this.assortmentId) {
            this._assortmentConfigs = this.getDefaultConfig();
            this.getAssortments();
        }
    }

    public get displayAsSlider(): boolean {
        return this.displayStyle == AssortmentStyle.SLIDER;
    }
    
    public get assortments(): Assortment[] {
        return this._assortments();
    }

    private getAssortments(): void {
        if (this.requestedAssortments) {
            if (this.prependExplictAssortments) {
                this._assortmentConfigs = this._assortmentConfigs.splice(0, 0, ...this.requestedAssortments);
            } else {
                this._assortmentConfigs.push(...this.requestedAssortments);
            }
        }

        for (let i = 0; i < this._assortmentConfigs.length; i++) {
            const assortmentId = this._assortmentConfigs[i].name;
            var filters = this._assortmentConfigs[i].filters;
            const placeHolder = new Assortment();
            placeHolder.assortmentId = assortmentId;
            this._assortments.update(old => {
                old.push(placeHolder)
                return old;
            });

            var request = new GetAssortmentRequest();
            request.url = this.url ?? "";
            request.assortmentId = assortmentId;
            request.filters = filters;

            this._assortmentService.getAssortment(request).subscribe((res) => {
                if (res && res.length > 0) {
                    for (let j = i; j < this._assortments().length; j++) {
                        if (this._assortments()[j].assortmentId == assortmentId) {
                            this._assortments.update(old => {
                                old.splice(j, 1, ...res);
                                return old;
                            });

                            this.onAssortmentLoaded.next(res);

                            break;
                        }
                    }
                }
            });
        }
    }

    private getDefaultConfig = () : AssortmentSettings[] => {
        const config = new AssortmentSettings();
        config.name = this.assortmentId;

        return new Array<AssortmentSettings>(config);
    }
}
