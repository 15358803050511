import { Component, Input } from '@angular/core';
import { ErrorComponent } from './error.component';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'base-component',
    template: 'this is a base component',
    standalone: true,
    imports: [CommonModule]
})
export class InvasiveErrorComponent extends ErrorComponent {
    @Input() allowDismissal: boolean;
    @Input() displayRetry: boolean;
    @Input() hideReload: boolean;
    @Input() hideHelp: boolean;
    @Input() urgency: string;
}
