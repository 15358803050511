import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PageManagerSystemHealth } from '@shared/Models/PageManagerModel';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PageManagerService {
    private _origin: string;

    constructor(
        private http: HttpClient,
        @Inject('ORIGIN_URL') originUrl: string) {

        this._origin = originUrl;
    }

    public getPageManagerSystemHealth(): Observable<PageManagerSystemHealth> {
        return this.http.get<PageManagerSystemHealth>(this._origin + '/page-manager/api/system-health');
    }
}
